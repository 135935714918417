<template>
  <v-container>
    <v-card
      class="my-7 mx-auto"
      max-width="800"
    >
      <v-row class="ma-0">
        <v-col
          sm="5"
          cols="12"
          class="primary darken-1 pa-7 d-flex align-items-center rounded-left-xs"
        >
          <div class="ma-auto text-center white--text">
            <v-icon class="contact-icon primary--text darken-1">
              mdi-login
            </v-icon>
            <br />
            <div class="subtitle my-5">
              Have account? please
              <v-btn
                class="ml-2 white--text"
                outlined
                :to="{name:'login'}"
                small
              >
                Login
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          sm="7"
          cols="12"
          class="px-7 py-3 d-flex"
        >
          <v-card
            class="ma-auto"
            elevation="0"
            :width="$vuetify.breakpoint.smAndUp ? '70%' : '85%'"
          >
            <v-form
              ref="form"
              class="text-center"
              @submit.prevent="confirmInvite"
            >
              <div class="headline secondary--text font-weight-bold my-2">
                Conferma invito
              </div>
              <v-text-field
                v-model="user.name"
                :rules="[formRules.required]"
                label="Nome *"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="user.surname"
                :rules="[formRules.required]"
                label="Cognome *"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="user.email"
                label="Email *"
                type="email"
                class="my-5"
                required
                :rules="[formRules.required,formRules.emailRules]"
                disabled
              >
              </v-text-field>
              <v-text-field
                v-model="user.password"
                label="Password *"
                type="password"
                required
                :rules="[formRules.required]"
              >
              </v-text-field>
              <v-text-field
                v-model="user.password2"
                label="Conferma password *"
                type="password"
                required
                :rules="[formRules.required]"
              >
              </v-text-field>
              <v-checkbox
                v-model="user.privacy"
                dense
                label="Acconsento al Trattamento dei Dati Personali"
              ></v-checkbox>
              <v-checkbox
                v-model="user.marketing"
                dense
                label="Acconsento al Marketing"
              ></v-checkbox>
              <v-btn
                class="primary darken-1 white--text my-3"
                @click.prevent="confirmInvite"
              >
                Conferma Invito
              </v-btn>
              <v-alert
                v-if="error"
                dense
                outlined
                color="error"
                class="my-5"
                dismissible
              >
                {{ error }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    user: {
      email: '',
      password: '',
      password2: '',
      name: '',
      surname: '',
      privacy: '',
      marketing: '',
    },
    error: null,
    invite: {},
  }),
  computed: {
    ...mapGetters(['formRules']),
  },
  mounted() {
    this.getInvite()
  },
  methods: {
    register() {
      this.error = null
      if (this.$refs.form.validate()) {
        this.$store.dispatch('register', this.user).then(() => {
          this.$router.push({ name: 'Home' })
        }).catch(err => {
          if (err.response) {
            this.error = 'register error'
          }
        })
      }
    },
    async getInvite() {
      if (this.$route.params.tk) {
        try {
          const response = await this.$api.readInvite(this.$route.params.tk)
          this.invite = response.data
          this.user.email = this.invite.target_user_email
        } catch (e) {
          console.log(e)
        }
      }
    },
    async confirmInvite() {
      if (this.$refs.form.validate()) {
        try {
          await this.$api.registerInviteProfessional(this.$route.params.tk, this.user)
          await this.$store.dispatch('set_alert', { msg: 'Profilo creato', type: 'success' })
          await this.$store.dispatch('login', this.user).then(async () => {
            await this.$store.dispatch('reload_all_data')

            setTimeout(async () => {
              await this.$router.push({ name: 'dashboard' })
            }, 500)
          }).catch(err => {
            if (err.response) {
              this.error = 'login error'
            }
          })
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
  },
}
</script>
<style>
.contact-icon {
    background: white;
    border-radius: 50%;
    padding: 10px;
    margin: 20px;
}
</style>
